<template>
  <vx-table
    :resource="resource"
    :columns="columns"
    :filters="filters"
  />
</template>

<script>
import {
  VxTable,
  VxCellButtons,
  VxCellLink,
  VxCellBadges,
  VxCellMoney,
  VxCellDate,
  VxCellImage
} from '@/components/table'
import {
  boolStatuses,
  markets,
  categories,
  passDataToResource
} from '@/services/resources'
import { filterTypes } from '@/services/table'
import { boolStatusesOptions, boolStatusFormat } from '@/services/utils'
import { buildRoute, getRouteIdParam, path } from '@/router'

export default {
  name: 'MarketSkins',
  components: {
    VxTable,
    VxCellButtons,
    VxCellLink,
    VxCellImage
  },
  setup () {
    const resource = passDataToResource(markets.getAllSkins, {
      requestParams: {
        urlParams: { id: getRouteIdParam() },
        params: { include: 'skin,skin.file,skin.item,skin.item.category' }
      }
    })

    const columns = [
      { key: 'skin_id' },
      {
        key: 'skin.file',
        label: 'Image',
        component: VxCellImage
      },
      {
        key: 'skin',
        label: 'Skin Name',
        component: VxCellLink,
        tdAttr: ({ id, market_hash_name }) => ({
          label: market_hash_name,
          href: buildRoute(path.items, { query: { id } })
        })
      },
      {
        key: 'skin.is_stattrak',
        label: 'Is StatTrak',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (isStattrak) => boolStatusFormat(isStattrak)
      },
      {
        key: 'skin.is_souvenir',
        label: 'Is Souvenir',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (isSouvenir) => boolStatusFormat(isSouvenir)
      },
      {
        key: 'skin.item.category',
        label: 'Category',
        component: VxCellLink,
        tdAttr: ({ id, name }) => {
          return {
            label: name,
            subLabel: `#${id}`,
            href: buildRoute(path.categories, { query: { id } })
          }
        }
      },
      {
        key: 'total_available',
        sortable: true
      },
      {
        key: 'market_price',
        component: VxCellMoney,
        sortable: true
      },
      {
        key: 'updated_at',
        component: VxCellDate,
        sortable: true
      }
    ]

    const filters = [
      {
        key: 'skin_id',
        type: filterTypes.number
      },
      {
        key: 'market_hash_name',
        label: 'Skin name',
        type: filterTypes.text
      },
      {
        key: 'category_id',
        type: filterTypes.number
      },
      {
        key: 'weapon',
        label: 'Category',
        type: filterTypes.serverSelect,
        resource: categories.getNames,
        resourceMapper: ({ names }) => names.sort()
      },
      {
        key: 'is_stattrak',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      },
      {
        key: 'is_souvenir',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
